<template>
  <div>
    <b-row>
      <!-- Field: Full Name -->
      <b-col cols="12" md="12">
        <h4 class="mb-2">
          {{ getBranch.name }}
        </h4>
      </b-col>
      <b-col cols="12" md="12" class="mb-25">
        <span class="sm-size"
          >(Поддерживаются файлы в формате .jpg, .png, .tiff)
        </span>
      </b-col>
      <b-col cols="12" md="2">
        <b-media class="mb-2">
          <div class="d-flex flex-column">
            <label for="">Лого</label>
            <div class="slider_img"></div>
            <!--  <b-img :src="getBranch.imageUrlRu" fluid class="slider_img mb-2" /> -->

            <b-avatar
              :src="getBranch.logo"
              :variant="`light-primary`"
              size="100%"
              class="mb-1"
              rounded
            />
            <div class="d-flex flex-column">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class=""
                @click="$refs.refInputElRu.click()"
              >
                <input
                  ref="refInputElRu"
                  accept="image/png, image/jpeg, image/tiff"
                  type="file"
                  class="d-none"
                  @change="handleFileUploadLogo($event)"
                />
                <span class="d-none d-sm-inline">Загрузить</span>
                <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
              </b-button>
              <div>
                <small class="text-danger" v-if="imageRequiredLogo === 1"
                  >Поле Лого поддерживает только файлы в формате .jpg, .png,
                  .tiff</small
                >
              </div>
            </div>
          </div>
          <!-- d-flex -->
        </b-media>
      </b-col>

      <b-col cols="12" md="2">
        <b-media class="mb-2">
          <div class="d-flex flex-column">
            <label for="">Изображение</label>
            <div class="slider_img"></div>
            <!--  <b-img :src="getBranch.imageUrlRu" fluid class="slider_img mb-2" /> -->

            <b-avatar
              :src="getBranch.photo"
              :variant="`light-primary`"
              size="100%"
              class="mb-1"
              rounded
            />
            <div class="d-flex flex-column">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class=""
                @click="$refs.refInputElUz.click()"
              >
                <input
                  ref="refInputElUz"
                  accept="image/png, image/jpeg, image/tiff"
                  type="file"
                  class="d-none"
                  @change="handleFileUploadPhoto($event)"
                />
                <span class="d-none d-sm-inline">Загрузить</span>
                <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
              </b-button>
              <div>
                <small class="text-danger" v-if="imageRequiredPhoto === 1"
                  >Поле Лого поддерживает только файлы в формате .jpg, .png,
                  .tiff</small
                >
              </div>
            </div>
          </div>
          <!-- d-flex -->
        </b-media>
      </b-col>
    </b-row>

    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- Field: Full Name -->
          <b-col cols="12" md="3">
            <validation-provider
              #default="{ errors }"
              name="Название"
              rules="required"
            >
              <b-form-group label="Название" label-for="branch-name">
                <b-form-input
                  id="branch-name"
                  :state="errors.length > 0 ? false : null"
                  v-model="getBranch.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Full Adress -->
          <b-col cols="12" md="3">
            <validation-provider
              #default="{ errors }"
              name="Адрес"
              rules="required"
            >
              <b-form-group label="Адрес" label-for="branch-address">
                <b-form-input
                  id="branch-address"
                  :state="errors.length > 0 ? false : null"
                  v-model="getBranch.address"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="3">
            <validation-provider
              #default="{ errors }"
              name="Статус"
              rules="required"
            >
              <b-form-group label="Статус" label-for="branch-status">
                <v-select
                  v-model="getBranch.branchState"
                  :reduce="(status) => status.value"
                  :state="errors.length > 0 ? false : null"
                  :options="statusOptions"
                  label="title"
                  :clearable="false"
                  input-id="branch-status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: branchMerchant -->
          <b-col cols="12" md="3">
            <validation-provider
              #default="{ errors }"
              name="Мерчант"
              rules="required"
            >
              <b-form-group label="Мерчант" label-for="branch-merchant">
                <v-select
                  id="branch-merchant"
                  placeholder="Выберите мерчант"
                  v-model="selectedMerchant"
                  :options="sortedMerchants"
                  :state="errors.length > 0 ? false : null"
                  @search="getMerchants"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Пусто
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Full Adress -->
          <b-col cols="12" md="12">
            <b-form-group label="Локация" label-for="branch-location">
              <yandex-map
                id="branch-location"
                :settings="mapSettings"
                :coords="[
                  getBranch.location.latitude,
                  getBranch.location.longitude,
                ]"
                :zoom="12.5"
                @click="onClick"
                class="branch-map"
              >
                <ymap-marker
                  markerId="123"
                  :coords="[
                    getBranch.location.latitude,
                    getBranch.location.longitude,
                  ]"
                />
              </yandex-map>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="12">
            <!-- Action Buttons -->
            <b-button
              @click.prevent="handleSubmit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import axiosIns from "@/libs/axios.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";

export default {
  components: {
    yandexMap,
    ymapMarker,
    ToastificationContent,
    BBadge,
    BFormTextarea,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {},
  watch: {
    /* getBranch() {
      this.coords.push(this.getBranch.location.latitude);
      this.coords.push(this.getBranch.location.longitude);
    }, */
    getMerchant(val) {
      this.selectedMerchant = val;
    },
  },
  computed: {
    ...mapGetters(["getBranch", "getMerchant"]),
    statusVariant() {
      const statusVariants = {
        /* eslint-disable key-spacing */
        ACTIVE: {
          color: "light-success",
          text: "Активный",
        },
        DELETED: {
          color: "light-danger",
          text: "Удаленный",
        },
        BLOCKED: {
          color: "light-warning",
          text: "Заблокированный",
        },
        SUSPENDED: {
          color: "light-secondary",
          text: "Приостановленный",
        },
        /* eslint-enable key-spacing */
      };

      return (status) => statusVariants[status];
    },
  },
  data() {
    return {
      logo: null,
      photo: null,
      categories: null,

      imageRequiredLogo: 0,
      imageRequiredPhoto: 0,

      mapSettings: {
        apiKey: "ff6e2790-0d09-4e1a-b157-adfec8de90d7",
        lang: "ru_RU",
        coordorder: "latlong",
        enterprise: false,
        version: "2.1",
      },
      coords: [],

      sortedMerchants: [],
      selectedMerchant: null,

      statusOptions: [
        {
          title: "Активный",
          value: "ACTIVE",
        },
        {
          title: "Удаленный",
          value: "DELETED",
        },
        {
          title: "Закрытый",
          value: "CLOSED",
        },
        {
          title: "Приостановленный",
          value: "SUSPENDED",
        },
      ],
    };
  },
  mounted() {
    this.getMerchants();
  },
  methods: {
    getRandomKey() {
      return Math.random().toString().replace("0.", "");
    },
    async handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axiosIns
            .put("branches/edit", {
              name: this.getBranch.name,
              address: this.getBranch.address,
              logo: this.getBranch.logo,
              photo: this.getBranch.photo,
              location: {
                latitude: this.getBranch.location.latitude,
                longitude: this.getBranch.location.longitude,
              },
              vendorId: this.selectedMerchant.id,
              branchId: this.getBranch.id,
              branchState: this.getBranch.branchState,
              cityId: this.getBranch.city.id,
            })
            .then((response) => {
              if (response.status == 200) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Успешно`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Филиал был успешно изменен!`,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ошибка`,
                    icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                    variant: "danger",
                    text: error.response.data.errorMessage,
                  },
                },
                {
                  position: "top-right",
                }
              );
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .finally(() => {
              this.$router.push({ name: "branches" });
            });
        }
      });
    },
    onClick(e) {
      this.coords = e.get("coords");
      this.getBranch.location.latitude = e.get("coords")[0];
      this.getBranch.location.longitude = e.get("coords")[1];
    },
    async handleFileUploadLogo(event) {
      this.logo = event.target.files[0];
      if (
        this.logo.type != "image/jpg" &&
        this.logo.type != "image/jpeg" &&
        this.logo.type != "image/png" &&
        this.logo.type != "image/tiff"
      ) {
        this.imageRequiredLogo = 1;
        return false;
      }
      this.imageRequiredLogo = 2;
      const formData = new FormData();
      formData.append("logo", this.logo);
      const response = await axiosIns.post("image/upload/branch", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.getBranch.logo = response.data.data.imageUrl;
    },

    async handleFileUploadPhoto(event) {
      this.photo = event.target.files[0];
      if (
        this.photo.type != "image/jpg" &&
        this.photo.type != "image/jpeg" &&
        this.photo.type != "image/png" &&
        this.photo.type != "image/tiff"
      ) {
        this.imageRequiredPhoto = 1;
        return false;
      }
      this.imageRequiredPhoto = 2;
      const formData = new FormData();
      formData.append("logo", this.photo);
      const response = await axiosIns.post("image/upload/branch", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.getBranch.photo = response.data.data.imageUrl;
    },
    async getMerchants(search) {
      const response = await axiosIns
        .get("vendor/all-active", {
          params: {
            page: 1,
            size: 20,
            field: "name",
            fieldValue: search,
          },
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      this.sortedMerchants = response.data.data.vendors;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
